import React from "react"
import Helmet from "react-helmet"
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import '../assets/index.scss'
import styles from './itinerary.module.scss';
import SecondaryLayout from '../components/Layout/secondaryLayout'
import get from "lodash/get";
const logo = require('../assets/images/minjLogo.png')
import Modal from "../components/Modal";
import _ from "lodash";
import {
    FacebookIcon,
    FacebookShareButton, GooglePlusIcon, GooglePlusShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

export default class Tours extends React.Component {
    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    modalToggle = (e) => {
        console.log(e.target.dataset.package)
        this.modal.current.modalToggle(e.target.dataset.package);
    };
    render(){
        const itinerary = get(
            this,
            'props.data.markdownRemark'
        )
        const slug = get( this,'props.pageContext.slug')
        return (
            <SecondaryLayout>
                <Helmet title={`${itinerary.frontmatter.title} | Minj Tour & Travels`} />
                <section className={styles.category + " hero is-info is-fullheight"} style={{background: 'url(' + itinerary.frontmatter.cover + ') no-repeat center', backgroundSize:'cover'}}>
                    <div className="hero-body has-margin-top-xl">
                        <div className="container has-text-centered">
                            <h1 className="title is-size-1">
                                {itinerary.frontmatter.title}
                            </h1>
                            <h2 className="title is-size-3">
                                Bookings Open
                            </h2>
                            <div className={styles.tourLink}>
                                <a data-package={itinerary.frontmatter.name} onClick={(e) => this.modalToggle(e)}>Book Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="hero-footer has-text-right">

                    </div>
                </section>
                <div className={styles.shareButtons}>
                    <ul className="menu-list">
                        <li>
                            <FacebookShareButton quote="Checkout this Awesome Travel Package I Found.." url={'https://minjtourntravels.com'+ slug} className="has-text-white-bis" >
                                <FacebookIcon round size={32}/>
                            </FacebookShareButton>
                        </li>
                        <li>
                            <WhatsappShareButton url={'https://minjtourntravels.com'+ slug} className="has-text-white-bis" >
                                <WhatsappIcon round size={32}/>
                            </WhatsappShareButton>
                        </li>
                        <li>
                            <TwitterShareButton url={'https://minjtourntravels.com'+ slug} className="has-text-white-bis" >
                                <TwitterIcon round size={32}/>
                            </TwitterShareButton>
                        </li>
                        <li>
                            <GooglePlusShareButton url={'https://minjtourntravels.com'+ slug} className="has-text-white-bis" >
                                <GooglePlusIcon round size={32}/>
                            </GooglePlusShareButton>
                        </li>
                    </ul>
                </div>
                <div className={styles.iWrapper + " text-montserrat"}>
                    <div className={styles.itinerary}>
                        {/*<img src={logo} alt=""/>*/}
                        <h1 className="heading is-size-2 has-text-centered has-color-primary">{itinerary.frontmatter.name}</h1>
                        <p className="has-text-centered">Contact No. - 8709285656, 9955120517, 8580361618</p>
                        <h2 className="subtitle has-text-centered is-uppercase is-marginless">Tour Date :<strong> {itinerary.frontmatter.duration}, [ {itinerary.frontmatter.travel} Days ]</strong></h2>
                        <h2 className="subtitle has-text-centered">TOUR COST : <strong>&#8377; {itinerary.frontmatter.cost}</strong></h2>
                        <div className="columns is-multiline is-fullwidth has-text-centered">
                            {itinerary.frontmatter.places.map((place, index) => <h2 key={index} className="column is-size-4 has-color-accent">{place}</h2>)}
                        </div>
                        <div
                            className="post-content"
                            dangerouslySetInnerHTML={{ __html: itinerary.html }}
                        />
                    </div>
                </div>
                <Modal ref={this.modal}/>
            </SecondaryLayout>
        )
    }
}

export const pageQuery = graphql`
  query TourBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        name
        cover
        date(formatString: "YYYY MM DD")
        places
        cost
        duration
      }
    }
  }
`